<script>
    export let innerWidth; // from App.svelte
    
    let profileList = [
            { 
                id: 0,
                src: "/images/profiles/man_profile_pc_01.jpg",
                name: "신인근",
                position: "대표이사",
                title: '보다 본질에 가까운 금융서비스를 제공하겠습니다.',
                department: '',
                introduction: '276홀딩스를 창업한 연쇄창업자입니다. 중앙대학교 법학과를 졸업한 후 현대종합상사,한화/무역,포스코인터내셔널에서 전 세계를 대상으로 한 다수의 프로젝트를 성공적으로 수행 중 공급망사슬 내 운전자금의 불합리성을 발견하고 2016년 코스콤과 합작하여 핀테크 스타트업을 설립, 인수합병을 통해 성공적으로 엑싯 하였습니다. 2019년 12월, 단순 자금 지원 차원의 운전자금 솔루션을 벗어나 중소기업들이 스스로 창출할 수 있는 신용시장을 개척하여 대한민국 경제에 새로운 변화를 만들어 가고자 합니다.',
                isDetailShow: false,
            },
            {
                id: 1,
                src: "/images/profiles/man_profile_pc_03.jpg",
                name: "박진용",
                position: "영업이사",
                title: '금융이 기업들에게 편리하고 폭넓게 활용될 수 있도록 변화시키고 있습니다.',
                department: '',
                introduction: '유플러스 클라우드팀 디자인 총괄을 거쳐, 코스콤에서 UI/UX 분야 설계 및 디자인을 담당하여 금투협 통합채권거래소시스템, 아제르바이잔거래소 등 금융시스템 UI 디자인을 전담하고 한국거래소 장운용 시스템 화면 관리 등의 업무를 담당하였습니다.',
                isDetailShow: false
            },
            {
                id: 2,
                src: "/images/profiles/man_profile_pc_02.jpg",
                name: "송인탁",
                position: "운영이사",
                title: '금융시장을 향한 변화, 그리고 조화',
                department: '',
                introduction: '성균관대학교에서 경제학을 전공하고 NH투자증권에서 투자전략 및 기업분석업무를 담당하였습니다. 276홀딩스에서 제시할 기업금융의 새로운 비전을 위해 힘차게 달려가겠습니다.',
                isDetailShow: false
            },
            {
                id: 3,
                src: "/images/profiles/man_profile_pc_04.jpg",
                name: "예충희",
                position: "영업팀장",
                title: '돈맥경화 해소를 위한 작은 날개짓, 변화의 바람을 만들겠습니다.',
                department: '',
                introduction: '2000년 기업 네트워크 영업을 시작으로 2008년부터 교보생명 법인영업팀, 핀테크 스타트업을 거쳐 276홀딩스에 합류하였습니다. 현장에서 다양한 중소기업의 애로사항을 경청하고 솔루션을 제공할 수 있도록 열심히 소통하고 최고의 금융서비스를 제공하겠습니다.',
                isDetailShow: false
            },
            {
                id: 4,
                src: "/images/profiles/man_profile_pc_05.jpg",
                name: "박충만",
                position: "개발팀장",
                title: '편리한 금융 서비스를 제공하겠습니다.',
                department: '',
                introduction: '컴퓨터공학을 전공하고 금융솔루션 개발회사부터 시작해 코스콤 합작 핀테크 스타트업 거쳐 현재 276홀딩스 개발팀장으로 함께하고 있습니다. 핀테크 개발에 누구보다 앞장서서 노력하겠습니다.',
                isDetailShow: false
            },
            {
                id: 5,
                src: "/images/profiles/man_profile_pc_06.jpg",
                name: "안대선",
                position: "운영팀원",
                title: '더 나은 금융을 위해 최선을 다하겠습니다.',
                department: '',
                introduction: '기업을 위한 더 나은 금융환경을 제공하고자 276홀딩스에 합류했습니다 276홀딩스의 이야기가 모든 기업에 닿을 수 있도록 노력하겠습니다',
                isDetailShow: false
            },
            {
                id: 6,
                src: "/images/profiles/man_profile_pc_07.jpeg",
                name: "최병희",
                position: "운영팀원",
                title: '기업과 상생할 수 있는 금융 서비스를 만들기 위해 노력하겠습니다.',
                department: '',
                introduction: '중앙대학교 법학과를 졸업 후 도시재생사업 실무 경험을 통해 함께 성장할 수 있는 서비스에 대해 고민하고 있습니다. 276홀딩스에서 중소기업과 함께 지속성장 가능한 금융 생태계 구축을 위해 노력하겠습니다. ',
                isDetailShow: false
            },
            {
                id: 7,
                src: "/images/profiles/man_profile_pc_08.jpg",
                name: "윤재호",
                position: "개발팀원",
                title: '고객친화적인 서비스를 만들겠습니다.',
                department: '',
                introduction: '경영정보학과를 전공하며 금융에 대한 지식과 컴퓨터공학에 대한 지식을 쌓은후 한국동서발전을 거쳐 276홀딩스에 합류했습니다. 고객이 사용하기 편리한, 사용하고 싶은 서비스를 만들기 위해 최선을 다하겠습니다. <p style="text-align: center;">정보처리기사</p>',
                isDetailShow: false
            },
            {
                id: 8,
                src: "/images/profiles/man_profile_pc_09.jpeg",
                name: "박윤혜",
                position: "개발팀원",
                title: '고객 중심 금융 서비스 구현을 위해 노력하겠습니다.',
                department: '',
                introduction: '경영학을 전공하여 쌓은 비즈니스 지식에 기술을 결합해서 편리한 금융 서비스를 제공하고자 합류했습니다. 고객의 요구사항을 적극 반영하는 서비스를 만들어가겠습니다.',
                isDetailShow: false
            },
    ];

    const handleToggle = (i, value) => {
        profileList = profileList.map(profiles => {
            if (profiles.id === i) {
                return {...profiles, isDetailShow: !value}
            } else {
                return {...profiles, isDetailShow: false};
            }
        });
    }

    const calcLeft = ( i, width ) => {
        let left = 113.7
        let unit = i % 5

        if (width <= 812) {
            // Mobile
            left = 150
            unit = i % 2
        }

        left = left * unit

        return left
    }
    
</script>

<div class="content-section">
    <div class="header-container">
        <h3>Members</h3>
    </div>
    <div class="container">
        <ul class="member-ul">
            {#each profileList as profiles, i}
            <li class="member-card">
                <div class="member-button member-hover" role="button" on:click={() => handleToggle(i, profiles.isDetailShow)}>
                    <img
                        class="member-img"
                        src={profiles.src}
                        alt={profiles.position}
                        onerror="this.src = '/images/img.jpg'"
                    />
                    <div class="member-contentsbox {profiles.isDetailShow ? 'member-detail-background' : 'member-default-background'}" >
                        <p class="member-position">{profiles.position}</p>
                        <p class="member-name">{profiles.name}</p>
                    </div>
                </div>
                <div class="member-detail" hidden={!profiles.isDetailShow} style="left: -{calcLeft(i, innerWidth)}%;"
                >
                    <div class="member-detail-contents">
                        <h3>{profiles.title}</h3>
                        <br />
                        <p>{@html profiles.introduction}</p>
                    </div>
                </div>
            </li>
            {/each}
        </ul>
    </div>
</div>

<style>
    .content-section {
        margin-top: 100px;
    }

    .header-container {
        text-align: center;
        margin-bottom:27px;
    }

    .member-ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        color: rgb(106, 106, 106);
        font-size: 15px;
        gap: 12px 2.5%;
    }

    .member-card {
        width: 18%;
    }

    .member-img {
        width: 100%;
    }

    .member-button p {
       text-align: center;
       margin: 0;
       font-weight: 500;
    }

    .member-position {
        font-size: 13px;
    }

    .member-name {
        font-weight: 400;
        margin-bottom: 0;
    }

    .member-hover {
        position: relative;
    }
    .member-detail-background {
        background-color: #F5F6F8;
    }

    .member-default-background {
        background-color: #fff;
    }

    .member-contentsbox {
        padding: 15px 0 15px 0;
        
    }

    .member-hover:hover .member-contentsbox {
        background-color: #F5F6F8;
    }

    .member-detail {
        width: 555%;
        background-color: #F5F6F8;
        position: relative;
        z-index: 1;
        display: block;
        max-height: 600px;
        overflow: hidden;
        transition: all 0.5s ease-in-out 0s;
        transition-delay: 0.5s;
    }

    .member-detail-contents {
        position: relative;
        font-weight: 400;
        text-align: center;
        padding: 36px;
        letter-spacing: -0.5px; 
    }

    .member-detail-contents h3{
        margin-top: 0.5rem;
        margin-bottom: 0;
        font-weight: 300;
    }

    p {
        text-align: left;
    }

    @media (max-width: 812px) {
        .member-ul {
            justify-content: space-between;
        }
        .member-card {
            width: 40%;
        }

        .member-detail {
            width: 250%;
        }
    }

</style>
