<script>
    import HeaderSection from "./components/HeaderSection.svelte";
    import ValueCreators from "./components/ValueCreators.svelte";
    import VisionValue from "./components/VisionValue.svelte";
    import FlowPoint from "./components/FlowPoint.svelte";
    import News from "./components/News.svelte";
    import Partners from "./components/Partners.svelte";
    import Members from "./components/Members.svelte";
    import Map from "./components/Map.svelte";
    import Footer from "./components/Footer.svelte";
    import TermsOfServiceModal from "./components/modal/footer/TermsOfServiceModal.svelte";
    import MarketingPolicyModal from "./components/modal/footer/MarketingPolicyModal.svelte";
    import ParnershipModal from "./components/modal/footer/ParnershipModal.svelte";

    $: innerWidth = 0; // 브라우저 innerWidth

    let modalOpenObj = {
        isTermsOfServiceModalOpen: false, // 이용약관
        isParnershipModalOpen: false, // 제휴문의
        isMarketingPolicyModalOpen: false, // 마케팅 정보 수신동의
    };

    function setModalOpenObj(name, value) {
        modalOpenObj[name] = value;
    }

    let is_open = false;

    function headerButtonClick() {
        is_open = !is_open;
    }
</script>

<svelte:window bind:innerWidth />

<main>
    <header>
        <div class="container">
            <div class="top-gnb">
                <a href="index.html">
                    <img
                        class="logo-img"
                        src="/images/bi01_2x.png"
                        alt="logo"
                    />
                </a>
                <!-- main header mobile button -->
                <div class="main-header-mobile-button">
                    <input type="checkbox" id="burger" />
                    <label class="burger" for="burger">
                        <span />
                        <span />
                        <span />
                    </label>

                    <ul class="dropdown-content" role="menu">
                        <li>
                            <button class="main-header-mobile-link-button">
                                <a
                                    class="main-header-mobile-link-button-text"
                                    href="https://flowpay.kr"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <p>매출채권 온라인관리</p>
                                    <p>플로우포인트</p>
                                </a>
                                <span
                                    class="main-header-mobile-link-button-arrow"
                                    >➜</span
                                >
                            </button>
                        </li>
                        <li>
                            <button class="main-header-mobile-link-button">
                                <a
                                    class="main-header-mobile-link-button-text"
                                    href="https://flowpay.kr/main/service"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <p>원자재 외상거래</p>
                                    <p>플로우페이</p>
                                </a>
                                <span
                                    class="main-header-mobile-link-button-arrow"
                                    >➜</span
                                >
                            </button>
                        </li>
                    </ul>
                </div>
                <!-- main header web button -->
                <div class="main-header-web-button">
                    <li>
                        <button class="main-header-link-button">
                            <a
                                class="main-header-link-button-text"
                                href="https://flowpay.kr"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <p>매출채권 온라인관리</p>
                                <p>플로우포인트</p>
                            </a>
                            <span class="main-header-link-button-arrow">➜</span>
                        </button>
                    </li>
                    <li>
                        <button class="main-header-link-button">
                            <a
                                class="main-header-link-button-text"
                                href="https://flowpay.kr/main/service"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <p>원자재 외상거래</p>
                                <p>플로우페이</p>
                            </a>
                            <span class="main-header-link-button-arrow">➜</span>
                        </button>
                    </li>
                </div>
            </div>
        </div>
    </header>

    <HeaderSection />

    <ValueCreators />

    <VisionValue />

    <FlowPoint />

    <Members {innerWidth} />

    <News />

    <Partners />

    <!--<Map />
        기존 존재하던 Map 컴포넌트 07.26 삭제 - 박이사님 요청사항
    <div class="footer">Copyright© by 276Holdings. All rights reserved.</div>-->

    <Footer {setModalOpenObj} />

    <TermsOfServiceModal
        isOpen={modalOpenObj.isTermsOfServiceModalOpen}
        {setModalOpenObj}
    />
    <!--<ParnershipModal isOpen={modalOpenObj.isParnershipModalOpen} setModalOpenObj={setModalOpenObj} />-->
    <MarketingPolicyModal
        isOpen={modalOpenObj.isMarketingPolicyModalOpen}
        {setModalOpenObj}
    />
</main>
